import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  ReviewItem,
  useGetReviewItems,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";
import { AccountingFormatter, AccountingStyle } from "../Formatters";

const ReviewGrid = ({ occupancy, filter }) => {
  const gridRef = useRef<AgGridReact<ReviewItem>>(null);
  const getReviewItems = useGetReviewItems(
    { occupancyId: occupancy.id },
    { enabled: false },
  );

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<ReviewItem, any> | ColGroupDef<ReviewItem>)[]
  >([
    {
      headerName: "Id",
      field: "id",
      hide: true,
    },
    {
      headerName: "Item",
      field: "itName",
      filter: "agSetColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
    },
    {
      headerName: "Type",
      field: "reType",
      filter: "agSetColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
    },
    {
      headerName: "Value",
      field: "reValue",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
    },
    {
      headerName: "Begin",
      field: "reBegin",
      type: "rightAligned",
    },
    {
      headerName: "Status",
      field: "reStatus",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Completed Date",
      field: "reCompleted",
      type: "rightAligned",
      enableRowGroup: true,
    },
    {
      headerName: "Is Closed",
      field: "reIsClosed",
      hide: true,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<ReviewItem[]>();

  useEffect(() => {
    getReviewItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.setFilterModel({
      reIsClosed: {
        values: ["false", "true"],
      },
    });
    gridRef.current?.api.applyColumnState({
      state: [
        { colId: "reStatus", sort: "asc", sortIndex: 0 },
        { colId: "reBegin", sort: "asc", sortIndex: 1 },
      ],
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <AgGridReact<ReviewItem>
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      quickFilterText={filter}
      ref={gridRef}
      rowData={rowData}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default ReviewGrid;
