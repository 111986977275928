import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  BillingItem,
  useGetBillingItems,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";
import { AccountingFormatter, AccountingStyle } from "../Formatters";

const BillingGrid = ({ occupancy, filter }) => {
  const gridRef = useRef<AgGridReact<BillingItem>>(null);
  const getBillingItems = useGetBillingItems(
    { occupancyId: occupancy.id },
    { enabled: false },
  );

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<BillingItem, any> | ColGroupDef<BillingItem>)[]
  >([
    {
      headerName: "Account",
      field: "acName",
      filter: "agSetColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
    },
    {
      headerName: "Billing Contact",
      field: "bcName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Type",
      field: "biType",
      filter: "agSetColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
    },
    {
      headerName: "Status",
      field: "biStatus",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Begin",
      field: "biBegin",
      type: "rightAligned",
    },
    {
      headerName: "End",
      field: "biEnd",
      type: "rightAligned",
    },
    {
      headerName: "Amount",
      field: "liAmount",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
    },
    {
      headerName: "Tax",
      field: "liTax",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
    },
    {
      headerName: "Total",
      field: "liTotal",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
    },
    {
      headerName: "Charge Id",
      field: "chId",
      hide: true,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<BillingItem[]>();

  useEffect(() => {
    getBillingItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.applyColumnState({
      state: [{ colId: "biBegin", sort: "desc", sortIndex: 0 }],
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <AgGridReact<BillingItem>
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      quickFilterText={filter}
      ref={gridRef}
      rowData={rowData}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default BillingGrid;
