import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Button } from "react-bootstrap";
import {
  ChargeItem,
  useGetChargeItems,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";
import { AccountingFormatter, AccountingStyle } from "../Formatters";

const ChargeGrid = ({ occupancy, filter, editCharge, viewBillings }) => {
  const gridRef = useRef<AgGridReact<ChargeItem>>(null);
  const getChargeItems = useGetChargeItems(
    { occupancyId: occupancy.id },
    { enabled: false },
  );

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<ChargeItem, any> | ColGroupDef<ChargeItem>)[]
  >([
    {
      headerName: "Actions",
      children: [
        {
          resizable: false,
          sortable: false,
          filter: false,
          suppressMenu: true,
          suppressMovable: true,
          maxWidth: 50,
          cellRenderer: (params) => {
            return (
              <Button
                variant="link"
                size="sm"
                className="m-0 p-0"
                onClick={() => editCharge(params.data)}
              >
                Edit
              </Button>
            );
          },
        },
        {
          resizable: false,
          sortable: false,
          filter: false,
          suppressMenu: true,
          suppressMovable: true,
          maxWidth: 65,
          cellRenderer: (params) => {
            return (
              <Button
                variant="link"
                size="sm"
                className="m-0 p-0"
                onClick={() => viewBillings(params.data)}
              >
                Billings
              </Button>
            );
          },
        },
      ],
    },
    {
      headerName: "Id",
      field: "id",
      hide: true,
    },
    {
      headerName: "Item",
      field: "itName",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "ChargeBasis",
      field: "chBasis",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Status",
      field: "chStatus",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Amount",
      field: "chAmount",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
    },
    {
      headerName: "Charge Begin",
      field: "chBegin",
      type: "rightAligned",
    },
    {
      headerName: "Charge End",
      field: "chEnd",
      type: "rightAligned",
    },
    {
      headerName: "Billed To",
      field: "chBilledTo",
      type: "rightAligned",
    },
    {
      headerName: "Type",
      field: "chType",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Includes Tax",
      field: "chIncTax",
      hide: true,
    },
    {
      headerName: "Is Closed",
      field: "chIsClosed",
      hide: true,
    },
    {
      headerName: "Is Voided",
      // field: "chIsVoided",
      hide: true,
    },
    {
      headerName: "Contacts",
      children: [
        {
          headerName: "Billing",
          field: "bcName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Legal",
          field: "lcName",
          filter: "agTextColumnFilter",
          hide: true,
        },
      ],
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<ChargeItem[]>();

  useEffect(() => {
    getChargeItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.applyColumnState({
      state: [
        { colId: "chStatus", sort: "asc", sortIndex: 0 },
        { colId: "chBegin", sort: "desc", sortIndex: 1 },
      ],
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      "ag-row-voided": (params) => {
        return params.data.chIsVoided;
      },
    };
  }, []);

  return (
    <AgGridReact<ChargeItem>
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      quickFilterText={filter}
      ref={gridRef}
      rowClassRules={rowClassRules}
      rowData={rowData}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default ChargeGrid;
